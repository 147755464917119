import { formatUrl, parseUrl } from '~/app/lib/router2/utils';
import { OrchardBrands } from '~/app/lib/songwhipApi/types';
import { UserTypes } from '~/types';

/**
 * A central place to define conditional redirects.
 */
const resolveAppRedirect = ({
  path,
  userId,
  userBrand,
  isCustomDomain,
}: {
  path: string;
  userId: number | undefined;
  userType: UserTypes | undefined;
  userBrand: OrchardBrands | undefined;
  isCustomDomain: boolean;
}) => {
  // Don't run redirect on custom domains as we don't own the paths here.
  // For example `mybrand.com/` might point to an ItemPage not the Songwhip homepage
  if (isCustomDomain) return;

  const { pathname, ...restUrl } = parseUrl(path);

  const redirectPath = resolvePath({
    pagePath: pathname,
    userBrand,
    userId,
  });

  if (!redirectPath) {
    return;
  }

  return formatUrl({
    pathname: redirectPath,
    ...restUrl,
  });
};

const resolvePath = ({
  pagePath,
  userId,
  userBrand,
}: {
  pagePath: string;
  userId: number | undefined;
  userBrand: OrchardBrands | undefined;
}) => {
  switch (pagePath) {
    case '/': {
      if (!userId) return '/login?redirectPath=/dashboard';
      return '/dashboard';
    }

    case '/faq/orchard': {
      if (!userId) return '/login?redirectPath=/faq/orchard';

      switch (userBrand) {
        case OrchardBrands.SME:
          return '/faq/sme';
        default:
          return;
      }
    }

    case '/faq/sme': {
      if (!userId) return '/login?redirectPath=/faq/sme';

      switch (userBrand) {
        case OrchardBrands.SME:
          return;
        default:
          return '/faq/orchard';
      }
    }

    case '/faq': {
      if (!userId) return '/login?redirectPath=/faq';

      switch (userBrand) {
        case OrchardBrands.SME:
          return '/faq/sme';
        default:
          return '/faq/orchard';
      }
    }

    default:
      return;
  }
};

export default resolveAppRedirect;
