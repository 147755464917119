import Debug from 'debug';

import { MappedTrack } from '../../songwhipApi/mapper/types';
import { AccountsActionTypes } from '../accounts/types';
import { AppReduxAction, StatusTypes, StoredError } from '../types';
import { TracksActionTypes as ActionTypes } from './types';

const debug = Debug('songwhip/store/tracks/reducer');

export interface StoredTrack extends Omit<MappedTrack, 'artists'> {
  artistIds: number[];
}

type TracksStateItem = {
  value: StoredTrack;
  status: StatusTypes;
  error?: StoredError;
};

export type TracksState = {
  [artistId: string]: TracksStateItem | undefined;
};

const initialState: TracksState = {};

const reducer = (
  state: TracksState = initialState,
  action: AppReduxAction
): TracksState => {
  switch (action.type) {
    case ActionTypes.FETCH_TRACK_START: {
      debug('on fetch album start: %s', action);

      return mergeTrackIntoState(state, action.trackId, {
        status: StatusTypes.PENDING,
      });
    }

    case ActionTypes.FETCH_TRACK_SUCCESS: {
      debug('on fetch album success: %s', action);
      const { payload } = action;

      return mergeTrackIntoState(state, payload.id, {
        status: StatusTypes.RESOLVED,
        value: toStoredTrack(payload),
        error: undefined,
      });
    }

    case ActionTypes.FETCH_TRACK_ERROR: {
      debug('on fetch track error: %s', action);

      return mergeTrackIntoState(state, action.trackId, {
        status: StatusTypes.REJECTED,
        error: action.error,
      });
    }

    // Purge all items when account config changes to ensure we never render
    // old `ownedByAccounts[].config`. This means that the item will be
    // re-fetched fresh from the server if/when CSR'd. This is a bit lazy
    // as we could selectively invalidate the items that have matching
    // ownedByAccounts[] but that would be more code to ship to every user :p
    case AccountsActionTypes.CONFIG_CHANGE: {
      return {};
    }

    default:
      return state;
  }
};

const mergeTrackIntoState = (
  state: TracksState,
  trackId: number,
  stateItem: Partial<TracksStateItem>
) => {
  const existingItem = state[trackId];

  const nextItem = {
    ...existingItem,
    ...stateItem,
  };

  return {
    ...state,
    [trackId]: nextItem,
  };
};

const toStoredTrack = (payload: MappedTrack): StoredTrack => {
  const { artists, ...rest } = payload;

  return {
    ...rest,
    artistIds: artists?.map(({ id }) => id),
  };
};

export default reducer;
