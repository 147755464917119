const DEFAULT_LANGUAGE = 'en';
const FRAGMENT_ROOT_KEY = 'app';

// when changing locales remember to check cypress/e2e tests (i18n.ts)
const locales = [
  DEFAULT_LANGUAGE,
  'es', // spanish
  'it', // italian
  'fr', // french
  'de', // german
  'pt', // portuguese
  'tr', // turkish
  'ja', // japanese
  'ko', // korean
  'zh-CN', // chinese
  'zh-TW', // taiwanese
];

const config = () => {
  return {
    defaultCode: DEFAULT_LANGUAGE,
    defaultLanguage: DEFAULT_LANGUAGE,
    fallbackToDefault: true,
    fragmentFileName: 'i18n.json',
    fragmentKeyByFolder: false,
    fragmentRootKey: FRAGMENT_ROOT_KEY,
    globalScope: false,
    inputPaths: ['./app', './pages'],
    typescript: true,
    outputPath: './locales',
    locales,
  };
};

config.DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
config.FRAGMENT_ROOT_KEY = FRAGMENT_ROOT_KEY;

module.exports = config;
